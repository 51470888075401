<script setup>
const { public: { BASE_URL } } = useRuntimeConfig()

useHead({
  title: 'Ранг - сервис для сбора отзывов привлечения клиентов из карт | Мотивирует оставить отзыв',
  htmlAttrs: { lang: 'ru' },
  meta: [
    { name: 'description', content: 'Ранг помогает бизнесу собирать хорошие настоящие отзывы и расти в рейтингах' },
    { name: 'apple-mobile-web-app-capable', content: 'yes' },
    { name: 'mobile-web-app-capable', content: 'yes' },
    { name: 'apple-mobile-web-app-title', content: 'Ранг' },
  ],
})
useSchemaOrg([
  defineBreadcrumb({
    itemListElement: [
      {
        position: 1,
        name: 'Главная Ранга',
        item: BASE_URL,
      },
    ],
  }),
])

await useSeoMeta({
  title: 'Ранг - сервис для сбора отзывов привлечения клиентов из карт | Мотивирует оставить отзыв',
  ogTitle: 'Ранг - сервис для сбора отзывов привлечения клиентов из карт | Мотивирует оставить отзыв',
  description: 'Ранг помогает бизнесу собирать хорошие настоящие отзывы и расти в рейтингах',
  ogDescription: 'Ранг помогает бизнесу собирать хорошие настоящие отзывы и расти в рейтингах',
  ogType: 'website',
  ogLocale: 'ru-RU',
})
</script>

<template>
  <main>
    <MainAudit />
    <MainShowCases contrast-background />
    <MainGeoService />
    <MainScoreDynamic contrast-background />
    <MainInteractiveEmoji />
    <MainSmsNotification contrast-background />
    <MainReviewMotivation />
    <MainFlexibleSystem contrast-background />
    <MainAttractUsers />
    <MainNegativeIntersection />
    <MainPatientsReview />
    <!-- <MainClientsReviews contrast-background /> -->
    <!--    <MainRangResult /> -->
  </main>
</template>
